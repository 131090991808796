<template>
  <div>
    <h1>Debug</h1>

    <v-btn color="success" @click="click">Print</v-btn>
    <v-btn color="success" @click="click2">click</v-btn>

    <!-- <div id="area">
      <v-card color="primary" dark :width="card.width" :height="card.height">
        <div class="d-flex flex-no-wrap justify-space-between">
          <v-avatar class="ma-3" :size="card.height * 0.7" tile>
            <v-img src="https://picsum.photos/200"></v-img>
          </v-avatar>

          <div>
            <v-card-title class="headline">ジャスティン田中</v-card-title>

            <v-card-subtitle>超弩級ビル</v-card-subtitle>

            <v-card-actions> </v-card-actions>
          </div>
        </div>
      </v-card>
    </div> -->

    <div class="A4">
      <section class="sheet padding-10mm">
        <!-- Write HTML just like a web page -->
        <article>This is an A4 document.</article>
      </section>
    </div>

    {{ patterns.items }}

    <v-card outlined>
      <v-btn-toggle v-model="xxx" color="primary" dense group multiple>
        <v-btn icon>
          <v-avatar color="red">
            SE
          </v-avatar>
        </v-btn>
        <v-btn>
          <v-icon>mdi-format-align-center</v-icon>
        </v-btn>
        <v-btn>
          <v-icon>mdi-format-align-right</v-icon>
        </v-btn>
        <v-btn>
          <v-icon>mdi-format-align-justify</v-icon>
        </v-btn>
      </v-btn-toggle>
    </v-card>

    <div class="text-center">
      <PatternMenu
        v-model="btn"
        color="primary"
        text-value="abbreviate"
        tooltip-value="name"
        :items="patterns.items"
        avatar-color="indigo"
        text-color="white"
        default-text="-"
        multiple
        dense
        :system-items="systemPatterns.items"
      />
      <p>
        {{ btn }}
      </p>
    </div>
    <vue-timepicker></vue-timepicker>
    <v-menu
      ref="menu"
      v-model="show"
      :close-on-content-click="false"
      :nudge-right="40"
      :return-value.sync="time"
      transition="scale-transition"
      offset-y
      max-width="290px"
      min-width="290px"
      :disabled="disabled"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-text-field
          label="label"
          readonly
          v-bind="attrs"
          v-on="on"
        ></v-text-field>
      </template>
      <vue-timepicker></vue-timepicker>
    </v-menu>
  </div>
</template>

<script>
import Vue from "vue";
import Common from "@/mixins/common";
import * as Dummy from "./dummy";
import VueTimepicker from "vue2-timepicker";
import "vue2-timepicker/dist/VueTimepicker.css";
export default {
  name: "Debug",
  mixins: [Common],
  components: {
    PatternMenu: () => import("@/components/PatternMenu"),
    "vue-timepicker": VueTimepicker
  },
  data() {
    return {
      show: false,
      width: 210,
      height: 297,
      margin: 10,
      card: {
        width: 91,
        height: 55
      },
      patterns: Dummy.getShiftPattern(), //["〇", "休", "第一", "第二"],
      systemPatterns: Dummy.getSystemPattern(),
      btn: [],
      xxx: null
    };
  },
  methods: {
    click() {
      this.printElement("area");
    },
    click2() {
      const canvas = document.getElementById("canvas");
      const ctx = canvas.getContext("2d");

      // ctx.strokeRect(0, 0, this.width, this.height);

      ctx.strokeRect(50, 50, 100, 100);

      // this.printElement("hoge");
    },
    printElement(id) {
      const area = document.getElementById(id); // 引数のidの箇所を変数に格納
      const htmlDoc = document.body.innerHTML; // bodyのhtmlを退避
      document.body.innerHTML = area.innerHTML; // 該当idのhtmlをdobyにつっこむ
      window.print(); // 印刷
      document.body.innerHTML = htmlDoc; // 退避したbodynohtmlを戻す
    }
  },
  created() {
    this.width *= 10;
    this.height *= 10;
    for (const key in this.card) {
      this.card[key] *= 5;
    }
  }
};
</script>

<style>
@page {
  size: A4;
}
</style>
